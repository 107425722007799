import { removeEventListener } from './windowUtils';
import config from '../config';

export const removeListener = (handler: any) => {
    removeEventListener("message", handler);
}

export const isAllowedOrigin = (event: MessageEvent): boolean => {
    return config.allowedOrigins.some(domain => event.origin.endsWith(domain));
}
