import { postMessage, addEventListener} from './windowUtils';
import { isAllowedOrigin } from './messages';
import { SetChatPromptMessage } from '../types';

export const postChatPromptMessage = (chatPrompt: string) => {
    const chatPromptMessage: SetChatPromptMessage = {
        service: "pumproom",
        type: "setChatPrompt",
        payload: { chatPrompt: chatPrompt },
        parentTargetIDs: ["it-doudou-modal-background"],
    };
    postMessage(chatPromptMessage, "*");
}

export const setChatPromptListener = (
    setChatPrompt: (chatPrompt: string) => {}
): (event: MessageEvent) => void => {
    const chatPromptListener = createChatPromptListener(setChatPrompt) as EventListener;
    addEventListener("message", chatPromptListener);
    return chatPromptListener
}

const createChatPromptListener = (
    setChatPrompt: (chatPrompt: string) => {}
) => {
    return (event: MessageEvent) => {
        if (!isAllowedOrigin(event)) {
            return;
        }
        const data: SetChatPromptMessage | undefined = event?.data;
        if (data?.service !== "pumproom" || data?.type !== "setChatPrompt") {
            return;
        }
        setChatPrompt(data.payload.chatPrompt);
    };
}
