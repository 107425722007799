import { useState, useEffect } from 'react';
import { removeListener } from '../lib/messages';
import { setChatPromptListener } from '../lib/chatPrompt';

const useChatPrompt = (): string | undefined => {
    const [chatPrompt, setChatPrompt] = useState<string | undefined>(undefined);

    useEffect(() => {
        const setChatPromptFromMessage = (chatPrompt: string) => {
            setChatPrompt(chatPrompt)
            return {};
        };
        const chatPromptListener = setChatPromptListener(setChatPromptFromMessage);

        return () => {
            if (chatPromptListener) {
                removeListener(chatPromptListener);
            }
        };
    }, []);

    return chatPrompt;
};

export default useChatPrompt;
