import axios from 'axios';
import config from '../config';

const apiClient = axios.create({
  baseURL: config.apiBaseUrl,
  timeout: config.apiTimeout,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  (apiConfig) => {
    apiConfig.headers['X-API-KEY'] = config.apiKey;
    return apiConfig;
  },
  (error) => Promise.reject(error) // Handle request errors
);

export default apiClient;
