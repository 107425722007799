import { useState, useEffect } from 'react';
import { setEnvironmentListener } from '../lib/environment';
import { removeListener } from '../lib/messages';
import { Environment } from '../types';
import { getHref } from '../lib/windowUtils';

const useEnvironment = (): Environment => {
    const defaultEnvironment: Environment = {
        pageURL: getHref(),
        snippetVersion: "0.0",
    };
    const [environment, setEnvironment] = useState<Environment>(defaultEnvironment);

    useEffect(() => {
        const setEnvironmentFromMessage = (environment: Environment) => {
            environment.pageURL = environment.pageURL || defaultEnvironment.pageURL;
            environment.snippetVersion = environment.snippetVersion || defaultEnvironment.snippetVersion;
            setEnvironment(environment)
            return {};
        };
        const environmentListener = setEnvironmentListener(setEnvironmentFromMessage);

        return () => {
            if (environmentListener) {
                removeListener(environmentListener);
            }
        };
    }, []);

    return environment;
};

export default useEnvironment;
