import React, { useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';
import Actions from './Actions';
import CodeEditor from './CodeEditor';
import FilePanel from './FilePanel';
import { PumpingTask, TaskFile, AvailableActions } from '../types';

interface CodeEditorFilesTabsPanelProps {
    currentTask: PumpingTask;
    setCurrentTask: React.Dispatch<
        React.SetStateAction<PumpingTask | undefined>
    >;
    explainPrompt: string | undefined;
    selectedFile: TaskFile;
    setSelectedFile: React.Dispatch<React.SetStateAction<TaskFile | undefined>>;
    setOpenResetTaskDialog: React.Dispatch<React.SetStateAction<boolean>>;
    postChatPrompt: (chatPrompt: string) => void;
    canRunCode: boolean;
    setCanRunCode: React.Dispatch<React.SetStateAction<boolean>>;
    onRunCode: () => Promise<void>;
    availableActions: AvailableActions;
}

const CodeEditorFilesTabsPanel: React.FC<CodeEditorFilesTabsPanelProps> = ({
    currentTask,
    setCurrentTask,
    explainPrompt,
    selectedFile,
    setSelectedFile,
    setOpenResetTaskDialog,
    postChatPrompt,
    canRunCode,
    setCanRunCode,
    onRunCode,
    availableActions,
}) => {
    const [activeMobileTab, setActiveMobileTab] = useState(1);

    const onFileSelection = (selectedFile: TaskFile) => {
        setSelectedFile(selectedFile);
        // Activate the editor tab when a file is selected
        setActiveMobileTab(1);
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveMobileTab(newValue);
    };

    const StyledTab = styled(Tab)(({ theme }) => ({
        fontSize: '12px', // Размер текста табов
        height: '12px', // Высота табов
        minHeight: '12px', // Минимальная высота табов
        minWidth: 'auto',
        padding: '0 3px', // Отступы по бокам для поддержания ширины
        textTransform: 'none',
    }));

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <Tabs
                value={activeMobileTab}
                onChange={handleTabChange}
                TabIndicatorProps={{
                    style: { backgroundColor: 'transparent' },
                }}
                sx={{
                    paddingLeft: '27px',
                    minHeight: '30px',
                }}
            >
                <StyledTab label='Файлы' />
                <StyledTab
                    label={`${
                        selectedFile.readonly || currentTask.read_only
                            ? 'Просмотр'
                            : 'Редактор'
                    }: ${selectedFile.path}`}
                />
            </Tabs>
            {activeMobileTab === 0 && (
                <Box
                    sx={{
                        display: { xs: 'flex', md: 'none' },
                        overflow: { xs: 'auto', md: 'none' },
                        flexGrow: 1,
                    }}
                >
                    <FilePanel
                        setSelectedFile={onFileSelection}
                        selectedFile={selectedFile}
                        currentTask={currentTask}
                    />
                </Box>
            )}
            {activeMobileTab === 1 && (
                <Box
                    sx={{
                        display: { xs: 'flex', md: 'none' },
                        overflow: { xs: 'hidden', md: 'none' },
                        gap: '16px',
                        flexGrow: 1,
                        flexDirection: 'column',
                        maxHeight: '100%',
                    }}
                >
                    <CodeEditor
                        selectedFile={selectedFile}
                        setSelectedFile={setSelectedFile}
                        currentTask={currentTask}
                        setCurrentTask={setCurrentTask}
                        setCanRunCode={setCanRunCode}
                    />
                    <Actions
                        currentTask={currentTask}
                        selectedFile={selectedFile}
                        explainPrompt={explainPrompt}
                        setOpenResetTaskDialog={setOpenResetTaskDialog}
                        postChatPrompt={postChatPrompt}
                        canRunCode={canRunCode}
                        onRunCode={onRunCode}
                        availableActions={availableActions}
                    />
                </Box>
            )}
        </Box>
    );
};

export default CodeEditorFilesTabsPanel;
