import React, { useState, useEffect, HTMLAttributes } from 'react';
import { Box, Paper } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Split from '@uiw/react-split';
import FilePanel from './FilePanel';
import ResultPanel from './ResultPanel';
import CodeEditorPanel from './CodeEditorPanel';
import CodeEditorFilesTabsPanel from './CodeEditorFilesTabsPanel';
import ResetTaskDialog from './ResetTaskDialog';
import { postChatPromptMessage } from '../lib/chatPrompt';
import {
    PumpingTask,
    TaskFile,
    TaskSubmissionOutput,
    AvailableActions,
} from '../types';

interface IDEProps {
    taskSubmission: TaskSubmissionOutput | undefined;
    loadingResult: boolean;
    currentTask: PumpingTask;
    setCurrentTask: React.Dispatch<
        React.SetStateAction<PumpingTask | undefined>
    >;
    selectedFile: TaskFile;
    setSelectedFile: React.Dispatch<React.SetStateAction<TaskFile | undefined>>;
    initialTask: PumpingTask;
    applyTask: (
        currentTask: PumpingTask | undefined,
        selectedFilePath: string | undefined
    ) => Promise<void>;
}

const IDE: React.FC<IDEProps> = ({
    taskSubmission,
    loadingResult,
    currentTask,
    setCurrentTask,
    selectedFile,
    setSelectedFile,
    initialTask,
    applyTask,
}) => {
    const [pristineTask, setPristineTask] = useState<boolean>(true);
    const [imitateLoading, setImitateLoading] = useState(true);
    const [canRunCode, setCanRunCode] = useState(true);
    const [openResetTaskDialog, setOpenResetTaskDialog] = useState(false);

    const theme = useTheme();
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));

    const defaultGap: string = '2px';
    const defaultBorderRadius: string = '15px';

    const onRunCode = async () => {
        setPristineTask(false);
        await applyTask(
            currentTask,
            selectedFile.path);
    };

    const onResetTask = () => {
        setCurrentTask(structuredClone(initialTask));
        const initialFile: TaskFile | undefined =
            initialTask.files.find((file) => file.path === selectedFile.path) ||
            initialTask.files[0];
        if (initialFile) {
            setSelectedFile(structuredClone(initialFile));
        }
        setOpenResetTaskDialog(false);
    };

    const onCloseResetTaskDialog = () => {
        setOpenResetTaskDialog(false);
    };

    const imitateIDELoadingEffect = async () => {
        await new Promise((resolve) => setTimeout(resolve, 750));
        setImitateLoading(false);
    };

    const postChatPrompt = (chatPrompt: string) => {
        postChatPromptMessage(chatPrompt);
    };

    interface RenderBarProps extends HTMLAttributes<HTMLDivElement> {
        onMouseDown?: (
            event: React.MouseEvent<HTMLDivElement, MouseEvent>
        ) => void;
    }

    const renderVerticalSplitBar = ({
        onMouseDown,
        ...props
    }: RenderBarProps) => {
        return (
            <div
                {...props}
                style={{
                    boxShadow: 'none',
                    background: 'transparent',
                    height: defaultGap,
                }}
            >
                <div onMouseDown={onMouseDown} />
            </div>
        );
    };

    const renderHorizontalSplitBar = ({
        onMouseDown,
        ...props
    }: RenderBarProps) => {
        return (
            <div
                {...props}
                style={{
                    boxShadow: 'none',
                    background: 'transparent',
                    width: defaultGap,
                }}
            >
                <div onMouseDown={onMouseDown} />
            </div>
        );
    };

    useEffect(() => {
        imitateIDELoadingEffect();
    }, []);

    const availableActions: AvailableActions = currentTask.read_only
        ? {
              run: false,
              reset: false,
              explain: true,
              copy: true,
          }
        : {
              run: true,
              reset: true,
              explain: true,
              copy: false,
          };

    let explainPrompt: string | undefined = undefined;
    if (currentTask.read_only && selectedFile.content) {
        explainPrompt = `Объясни этот файл ${selectedFile.path}:\n${selectedFile.content}`;
    } else if (taskSubmission?.explain_prompt && taskSubmission?.ready) {
        explainPrompt = taskSubmission.explain_prompt;
    }

    return (
        <>
            <Box
                id='ide-container'
                sx={{
                    height: '100vh',
                    width: '100vw',
                    overflow: 'hidden',
                    padding: defaultGap,
                }}
            >
                <Split
                    mode='vertical'
                    visible={!currentTask.read_only}
                    disable={currentTask.read_only}
                    renderBar={renderVerticalSplitBar}
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <Box
                        id='ide-top-panels-row'
                        sx={{
                            height: currentTask.read_only ? '100vh' : '55vh',
                            minHeight: '200px',
                            maxHeight: '100%',
                            display: 'flex',
                            width: '100%',
                        }}
                    >
                        {isDesktopScreen && (
                            <Box
                                id='ide-desktop-panel'
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                <Split
                                    mode='horizontal'
                                    renderBar={renderHorizontalSplitBar}
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        display: 'flex',
                                    }}
                                >
                                    {/* Render Files for desktop */}
                                    <Box
                                        id='ide-files-panel'
                                        sx={{
                                            height: '100%',
                                            flexDirection: 'row',
                                            borderRadius: defaultBorderRadius,
                                            minWidth: '250px',
                                            width: '30%',
                                        }}
                                        className={
                                            imitateLoading ? 'skeleton' : ''
                                        }
                                    >
                                        {!imitateLoading && (
                                            <FilePanel
                                                setSelectedFile={
                                                    setSelectedFile
                                                }
                                                selectedFile={selectedFile}
                                                currentTask={currentTask}
                                            />
                                        )}
                                    </Box>
                                    {/* Render Code Editor for desktop */}
                                    <Paper
                                        id='ide-editor-panel'
                                        elevation={0}
                                        className={
                                            imitateLoading ? 'skeleton' : ''
                                        }
                                        sx={{
                                            padding: '25px 15px 22px 22px',
                                            flexDirection: 'column',
                                            minWidth: '450px',
                                            width: '100%',
                                            maxWidth: '100%',
                                            height: '100%',
                                            borderRadius: defaultBorderRadius,
                                        }}
                                    >
                                        {!imitateLoading && (
                                            <Box
                                                sx={{
                                                    height: '100%',
                                                    width: '100%',
                                                }}
                                            >
                                                <CodeEditorPanel
                                                    currentTask={currentTask}
                                                    setCurrentTask={
                                                        setCurrentTask
                                                    }
                                                    explainPrompt={
                                                        explainPrompt
                                                    }
                                                    selectedFile={selectedFile}
                                                    setSelectedFile={
                                                        setSelectedFile
                                                    }
                                                    setOpenResetTaskDialog={
                                                        setOpenResetTaskDialog
                                                    }
                                                    postChatPrompt={
                                                        postChatPrompt
                                                    }
                                                    canRunCode={canRunCode}
                                                    setCanRunCode={
                                                        setCanRunCode
                                                    }
                                                    onRunCode={onRunCode}
                                                    availableActions={
                                                        availableActions
                                                    }
                                                ></CodeEditorPanel>
                                            </Box>
                                        )}
                                    </Paper>
                                </Split>
                            </Box>
                        )}

                        {!isDesktopScreen && (
                            <Paper
                                id='ide-files-editor-mobile-panel'
                                elevation={0}
                                className={imitateLoading ? 'skeleton' : ''}
                                sx={{
                                    padding: '15px 10px 20px 8px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: defaultBorderRadius,
                                }}
                            >
                                {!imitateLoading && (
                                    <Box
                                        sx={{
                                            height: '100%',
                                        }}
                                    >
                                        <CodeEditorFilesTabsPanel
                                            currentTask={currentTask}
                                            setCurrentTask={setCurrentTask}
                                            explainPrompt={explainPrompt}
                                            selectedFile={selectedFile}
                                            setSelectedFile={setSelectedFile}
                                            setOpenResetTaskDialog={
                                                setOpenResetTaskDialog
                                            }
                                            postChatPrompt={postChatPrompt}
                                            canRunCode={canRunCode}
                                            setCanRunCode={setCanRunCode}
                                            onRunCode={onRunCode}
                                            availableActions={availableActions}
                                        />
                                    </Box>
                                )}
                            </Paper>
                        )}
                    </Box>

                    {!currentTask.read_only && (
                        <Paper
                            id='ide-result-panel'
                            elevation={0}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                overflow: 'hidden',
                                borderRadius: defaultBorderRadius,
                                padding: {
                                    xs: '10px 8px',
                                    md: '25px 15px 22px 22px',
                                },
                                height: '45vh',
                                minHeight: '150px',
                                maxHeight: '100%',
                                width: '100%',
                            }}
                            className={imitateLoading ? 'skeleton' : ''}
                        >
                            {!imitateLoading && (
                                <ResultPanel
                                    taskSubmission={taskSubmission}
                                    loadingResult={loadingResult}
                                    pristine={pristineTask}
                                />
                            )}
                        </Paper>
                    )}
                </Split>
            </Box>
            <ResetTaskDialog
                openResetTaskDialog={openResetTaskDialog}
                onResetTask={onResetTask}
                onCloseResetTaskDialog={onCloseResetTaskDialog}
            ></ResetTaskDialog>
        </>
    );
};

export default IDE;
