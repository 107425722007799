// Function to get the current URL
export function getHref(): string {
    return window.location.href;
}

// Function to get the current search query string
export function getSearchParams(): URLSearchParams {
    return new URLSearchParams(window.location.search);
}

// Function to post a message to the parent window
export function postMessage(message: any, targetOrigin: string): void {
    window.parent.postMessage(message, targetOrigin);
}

// Function to add an event listener to the window
export function addEventListener(type: string, listener: EventListenerOrEventListenerObject): void {
    window.addEventListener(type, listener);
}

// Function to remove an event listener from the window
export function removeEventListener(type: string, listener: EventListenerOrEventListenerObject): void {
    window.removeEventListener(type, listener);
}

// Function to reload the current page
export function reload(): void {
    window.location.reload();
}
