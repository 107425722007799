import { postMessage, addEventListener } from './windowUtils';
import { isAllowedOrigin } from './messages';
import {
    GetTildaProfileMessage, SetTildaProfileMessage, ProfileInput, CourseInput
} from '../types';

export const setTildaProfileListener = (
    setUserProfile: (tildaProfile: ProfileInput) => {}
): (event: MessageEvent) => void => {
    const tildaProfileListener = createTildaProfileListener(setUserProfile) as EventListener;
    addEventListener("message", tildaProfileListener);
    const message: GetTildaProfileMessage = {
        service: "pumproom",
        type: "getTildaProfile",
    };
    postMessage(message, "*");
    return tildaProfileListener
}

const createTildaProfileListener = (
    setUserProfile: (tildaProfile: ProfileInput) => {}
) => {
    return (event: MessageEvent) => {
        if (!isAllowedOrigin(event)) {
            return;
        }
        const data: SetTildaProfileMessage | undefined = event.data;
        if (data?.service !== "pumproom" || data?.type !== "setTildaProfile") {
            return;
        }
        try {
            setUserProfile(data.payload);
        } catch (error) {
            console.error("Error setting User Profile:", error);
        }
    };
}

export const getDemoTildaProfile = () => {
    const courseInput: CourseInput = {
        alias: "demo_user_course",
        name: "demo_user_course",
        created: new Date(2024, 0, 1, 0, 0, 0)
    };

    return {
        courses: [courseInput],
        istutor: false,
        lang: "ru",
        login: "demo_user@inzhenerka.tech",
        name: "demo_user",
        projectid: "000000",
    };
}
