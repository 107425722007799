import React from 'react';
import { Typography, Box } from '@mui/material';
import EditOffIcon from '@mui/icons-material/EditOff';
import Actions from './Actions';
import CodeEditor from './CodeEditor';
import { PumpingTask, TaskFile, AvailableActions } from '../types';

interface CodeEditorPanelProps {
    currentTask: PumpingTask;
    setCurrentTask: React.Dispatch<
        React.SetStateAction<PumpingTask | undefined>
    >;
    explainPrompt: string | undefined;
    selectedFile: TaskFile;
    setSelectedFile: React.Dispatch<React.SetStateAction<TaskFile | undefined>>;
    setOpenResetTaskDialog: React.Dispatch<React.SetStateAction<boolean>>;
    postChatPrompt: (chatPrompt: string) => void;
    canRunCode: boolean;
    setCanRunCode: React.Dispatch<React.SetStateAction<boolean>>;
    onRunCode: () => Promise<void>;
    availableActions: AvailableActions;
}

const CodeEditorPanel: React.FC<CodeEditorPanelProps> = ({
    currentTask,
    setCurrentTask,
    explainPrompt,
    selectedFile,
    setSelectedFile,
    setOpenResetTaskDialog,
    postChatPrompt,
    canRunCode,
    setCanRunCode,
    onRunCode,
    availableActions,
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                gap: '8px',
                flexDirection: 'column',
                height: '100%',
                overflow: 'hidden',
                flexGrow: 1,
            }}
        >
            <Typography
                variant='h6'
                sx={{
                    ml: 1,
                    mb: 1,
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: {
                        xs: '11px',
                        md: '20px',
                    },
                    fontWeight: '400',
                }}
            >
                {`${
                    selectedFile.readonly || currentTask.read_only
                        ? 'Просмотр'
                        : 'Редактор'
                }: ${selectedFile.path}`}
                {(selectedFile.readonly || currentTask.read_only) && (
                    <EditOffIcon sx={{ ml: 1 }} />
                )}
            </Typography>
            <CodeEditor
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                currentTask={currentTask}
                setCurrentTask={setCurrentTask}
                setCanRunCode={setCanRunCode}
            />
            <Actions
                currentTask={currentTask}
                selectedFile={selectedFile}
                explainPrompt={explainPrompt}
                setOpenResetTaskDialog={setOpenResetTaskDialog}
                postChatPrompt={postChatPrompt}
                canRunCode={canRunCode}
                onRunCode={onRunCode}
                availableActions={availableActions}
            />
        </Box>
    );
};

export default CodeEditorPanel;
