import React from 'react';
import {
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
} from '@mui/material';

interface ResetTaskDialogProps {
    openResetTaskDialog: boolean;
    onResetTask: () => void;
    onCloseResetTaskDialog: () => void;
}

const ResetTaskDialog: React.FC<ResetTaskDialogProps> = ({
    openResetTaskDialog,
    onResetTask,
    onCloseResetTaskDialog,
}) => {
    return (
        <Dialog
            open={openResetTaskDialog}
            onClose={onCloseResetTaskDialog}
            PaperProps={{
                sx: {
                    margin: { xs: '0', md: '33px' },
                    borderRadius: { xs: '25px 25px 0 0', md: '16px' },
                    flexDirection: { xs: 'column', md: 'row' },
                    display: 'flex',
                    justifyContent: { xs: 'flex-start', md: 'center' },
                    marginTop: { xs: 'auto', md: '0' }, // Выравнивание вниз на мобильных устройствах
                    marginBottom: { xs: '0px', md: '0' }, // Дополнительный отступ снизу на мобильных
                },
            }}
        >
            <DialogContent
                sx={{
                    maxWidth: { xs: '100%', md: '345px' },
                    gap: { xs: '30px', md: '0' },
                    backgroundColor: 'white',
                }}
                style={{
                    width: '100%',
                    height: '240px',
                    borderRadius: '14px',
                    padding: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                color='black'
                fontSize={16}
                    style={{
                        textAlign: 'center',
                        fontWeight: '500',
                    }}
                >
                    Вы точно хотите сбросить код?
                </Typography>
                <Typography
                    sx={{
                        fontSize: { xs: '14px', md: '16px' },
                        width: { xs: '60%', md: '85%' },
                        fontWeight: { xs: '400', md: '400' },
                        color: { xs: '#9AA2AB', md: '#000' },
                    }}
                    style={{
                        textAlign: 'center',
                    }}
                >
                    Код сбрасывается во всех файлах и это действие нельзя
                    отменить
                </Typography>
                <DialogActions
                    sx={{
                        width: { xs: '100%', md: 'auto' },
                        marginTop: { xs: '0', md: '25px' },
                        gap: '8px',
                        flexDirection: { xs: 'row-reverse', md: 'row' },
                    }}
                >
                    <Button
                        sx={{
                            width: { xs: '100%', md: 'auto' },
                            minWidth: '150px',
                            height: { xs: '50px', md: '24px' },
                            padding: '2.5px 5px',
                            margin: '0px',
                        }}
                        size='small'
                        onClick={onCloseResetTaskDialog}
                        variant='contained'
                    >
                        Нет, не хочу
                    </Button>
                    <Button
                        sx={{
                            color: 'primary.main',
                            height: { xs: '50px', md: '24px' },
                            width: { xs: '100%', md: 'auto' },
                            minWidth: '150px',
                            padding: '2.5px 5px',
                            margin: '0px',
                        }}
                        onClick={onResetTask}
                        variant='outlined'
                        style={{
                        }}
                    >
                        Да, сбросить код
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default ResetTaskDialog;
