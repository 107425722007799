import { compareVersions } from 'compare-versions';
import { SnippetVersion, FeatureName } from '../types';
import config from '../config';

export const isFeatureAvailable = (currentVersion: SnippetVersion, feature: FeatureName): boolean => {
    if (currentVersion === '0.0') return true;
    
    const requiredVersion = config.features[feature];

    if (!requiredVersion) {
        console.warn(`Feature "${feature}" is not defined.`);
        return false;
    }

    return compareVersions(currentVersion, requiredVersion) >= 0;
};
