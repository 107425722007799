import { useEffect, useState } from 'react';
import { queryPermission } from '../lib/navigatorUtils';
import { Permissions } from '../types';



const usePermissions = (): Permissions => {
    const [permissions, setPermissions] = useState<Permissions>({
        clipboardWrite: false,
        fullscreen: false,
    });

    useEffect(() => {
        const checkPermissions = async () => {
            const clipboardWritePermission: PermissionState = await queryPermission('clipboard-write' as PermissionName);
            const fullscreenPermission = await queryPermission('fullscreen' as PermissionName);

            setPermissions({
                clipboardWrite: clipboardWritePermission === 'granted',
                fullscreen: fullscreenPermission === 'granted',
            });
        };

        checkPermissions();
    }, []);

    return permissions;
};

export default usePermissions;
