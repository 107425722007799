import React from 'react';
import { Stack, Button } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import useEnvironment from '../hooks/useEnvironment';
import usePermissions from '../hooks/usePermissions';
import { isFeatureAvailable } from '../lib/features';
import { getTaskQueryParams } from '../lib/queryParams';
import config from '../config';
import { copyToClipboard } from '../lib/navigatorUtils';
import {
    PumpingTask,
    FrameworkType,
    AvailableActions,
    TaskFile,
} from '../types';

interface ActionsProps {
    currentTask: PumpingTask;
    selectedFile: TaskFile;
    explainPrompt: string | undefined;
    setOpenResetTaskDialog: React.Dispatch<React.SetStateAction<boolean>>;
    postChatPrompt: (chatPrompt: string) => void;
    canRunCode: boolean;
    onRunCode: () => Promise<void>;
    availableActions: AvailableActions;
}

const Actions: React.FC<ActionsProps> = ({
    currentTask,
    selectedFile,
    explainPrompt,
    setOpenResetTaskDialog,
    postChatPrompt,
    canRunCode,
    onRunCode,
    availableActions,
}) => {
    const environment = useEnvironment();
    const { clipboardWrite, fullscreen } = usePermissions();
    const explainAvailable =
        isFeatureAvailable(environment.snippetVersion, 'explain') &&
        !getTaskQueryParams().noExplain;

    const handleOpenDialog = () => {
        setOpenResetTaskDialog(true);
    };

    function getCommandForButton(framework: FrameworkType | null): string {
        let command: string | null = null;
        if (framework) {
            command = config.framework_commands[framework] || null;
        }
        if (command) {
            return '\u203A ' + command;
        } else {
            return 'Запустить';
        }
    }

    return (
        <>
            <Stack
                id='actions'
                direction='row'
                spacing={2}
                className='actions'
                sx={{
                    flexDirection: { xs: 'row-reverse', md: 'row' },
                    flexWrap: 'wrap',
                    gap: { xs: '10px', md: '16px' },
                    paddingLeft: { xs: '10px', md: '25px' },
                }}
            >
                {availableActions.run && (
                    <Button
                        disabled={!canRunCode}
                        sx={{
                            minWidth: '150px',
                        }}
                        size='small'
                        variant='contained'
                        onClick={onRunCode}
                    >
                        {getCommandForButton(currentTask.framework)}
                    </Button>
                )}
                {availableActions.reset && (
                    <Button
                        sx={{
                            marginLeft: '0px !important',
                        }}
                        size='small'
                        variant='outlined'
                        onClick={handleOpenDialog}
                    >
                        Сбросить код
                    </Button>
                )}
                {availableActions.copy && clipboardWrite && (
                    <Button
                        sx={{
                            marginLeft: '0px !important',
                        }}
                        size='small'
                        variant='outlined'
                        onClick={() => copyToClipboard(selectedFile.content)}
                    >
                        Копировать
                    </Button>
                )}
                {availableActions.explain &&
                    explainPrompt &&
                    explainAvailable && (
                        <Button
                            sx={{
                                marginLeft: '0px !important',
                            }}
                            size='small'
                            variant='outlined'
                            startIcon={<AutoAwesomeIcon />}
                            onClick={() => postChatPrompt(explainPrompt)}
                        >
                            Объяснить
                        </Button>
                    )}
            </Stack>
        </>
    );
};

export default Actions;
