import apiClient from './apiClient';
import { AxiosError } from 'axios';
import { PumpingTask, LoadTaskInput, TaskSubmissionInput, TaskSubmissionOutput } from '../types';

export const loadTask = async (
    taskInput: LoadTaskInput,
    options?: { abortSignal?: AbortSignal }
): Promise<PumpingTask | undefined> => {
    try {
        const response = await apiClient.post(`/load_task`,
            taskInput,
            { signal: options?.abortSignal },
        );
        return response.data;
    } catch (error) {
        if ((error as AxiosError).code !== "ERR_CANCELED") {
            console.error('Error fetching task from API:', error);
            throw error;
        }
    }
};

export const applyTaskSubmission = async (taskSubmission: TaskSubmissionInput): Promise<TaskSubmissionOutput> => {
    try {
        const response = await apiClient.post(`/apply_task_submission`, taskSubmission);
        return response.data;
    } catch (error) {
        console.error('Error applying task submission to API:', error);
        throw error;
    }
};

export const getTaskSubmission = async (uid: string): Promise<TaskSubmissionOutput> => {
    try {
        const response = await apiClient.get(`/get_task_submission`, {
            params: {
                uid: uid
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error getting task submission status from API:', error);
        throw error;
    }
};
