import { postMessage, addEventListener} from './windowUtils';
import { isAllowedOrigin } from './messages';
import {
    GetEnvironmentMessage, SetEnvironmentMessage, Environment
} from '../types';

export const setEnvironmentListener = (
    setEnvironment: (environment: Environment) => {}
): (event: MessageEvent) => void => {
    const environmentListener = createEnvironmentListener(setEnvironment) as EventListener;
    addEventListener("message", environmentListener);
    const message: GetEnvironmentMessage = {
        service: "pumproom",
        type: "getEnvironment",
    };
    postMessage(message, "*");
    return environmentListener
}

const createEnvironmentListener = (
    setEnvironment: (environment: Environment) => {}
) => {
    return (event: MessageEvent) => {
        if (!isAllowedOrigin(event)) {
            return;
        }
        const data: SetEnvironmentMessage | undefined = event.data;
        if (data?.service !== "pumproom" || data?.type !== "setEnvironment") {
            return;
        }
        try {
            setEnvironment(data.payload);
        } catch (error) {
            console.error("Error setting Environment:", error);
        }
    };
}
